const apps = [
  {
    category: 'Web Browsers',
    apps: ['Google Chrome', 'Mozilla Firefox', 'Opera', 'Brave', 'Opera GX']
  },
  {
    category: 'Communication',
    apps: ['Slack', 'Zoom', 'Discord', 'WhatsApp', 'Pidgin', 'Thunderbird ESR']
  },
  {
    category: 'Media Players',
    apps: ['VLC', 'Spotify', 'iTunes', 'Winamp', 'QuickTime Player', 'AIMP', 'Audacity', 'GOM Player', 'PotPlayer', 'KMPlayer'] // Removed Skype, MusicBee, and HandBrake
  },
  {
    category: 'Dev Tools',
    apps: ['Visual Studio Code', 'Node.js', 'PyCharm', 'Sublime Text', 'Python 3', 'FileZilla', 'WinSCP', 'PuTTY', 'Eclipse', 'VirtualBox', 'VMware'] // Removed Git
  },
  {
    category: 'Documents',
    apps: ['Foxit Reader', 'LibreOffice', 'SumatraPDF', 'OpenOffice']
  },
  {
    category: 'Packaging',
    apps: ['WinRAR']
  }
];

export default apps;
