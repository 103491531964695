import React, { createContext, useState, useContext } from 'react';

const DownloadContext = createContext();

export function useDownload() {
  return useContext(DownloadContext);
}

export function DownloadProvider({ children }) {
  const [isDownloading, setIsDownloading] = useState(false);

  return (
    <DownloadContext.Provider value={{ isDownloading, setIsDownloading }}>
      {children}
    </DownloadContext.Provider>
  );
}
