import React, { useState, useEffect, memo } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import apps from './appsData';
import FeedbackPage from './FeedbackPage';
import Help from './Help';
import axios from 'axios';
import { useDownload } from './DownloadContext';

const translations = {
  en: {
    title: "Choose the Apps You'd Like to Download!",
    instruction: "How to Quick Install the Apps? Simple! Follow these two steps: Pick the apps you want to download and click the 'Download' button!",
    downloadButton: "Download Selected Apps",
    noSelection: "You Haven’t Selected Any Apps Yet",
    help: "Help",
    feedback: "Feedback",
    pleaseWait: "Please wait...",
    downloading: "Downloading...",
    errorDownloading: "Error during download. Please try again.",
    largeFileMessage: "Large file size, might take a moment.",
    categories: {
      'Web Browsers': "Web Browsers",
      'Communication': "Communication",
      'Media Players': "Media Players",
      'Dev Tools': "Dev Tools",
      'Documents': "Documents",
      'Security': "Security",
      'Packaging': "Packaging"
    }
  },
  pl: {
    title: "Wybierz aplikacje, które chcesz pobrać!",
    instruction: "Jak szybko zainstalować aplikacje? To proste! Postępuj zgodnie z tymi dwoma krokami: Wybierz aplikacje, które chcesz pobrać i kliknij przycisk 'Pobierz'!",
    downloadButton: "Pobierz wybrane aplikacje",
    noSelection: "Nie wybrałeś jeszcze żadnych aplikacji",
    help: "Pomoc",
    feedback: "Opinie",
    pleaseWait: "Proszę czekać...",
    downloading: "Pobieranie...",
    errorDownloading: "Błąd podczas pobierania. Spróbuj ponownie.",
    largeFileMessage: "Duży rozmiar pliku. Pobieranie może chwilę potrwać.",
    categories: {
      'Web Browsers': "Przeglądarki",
      'Communication': "Komunikacja",
      'Media Players': "Odtwarzacze",
      'Dev Tools': "Narzędzia Dev",
      'Documents': "Dokumenty",
      'Security': "Bezpieczeństwo",
      'Packaging': "Pakowanie"
    }
  }
};

const FallingStars = memo(() => {
  useEffect(() => {
    const starContainer = document.querySelector('.dot-container');
    starContainer.innerHTML = "";
    const starCount = 50;

    for (let i = 0; i < starCount; i++) {
      const star = document.createElement('div');
      star.classList.add('dot');
      star.style.left = `${Math.random() * 100}vw`;
      star.style.animationDelay = `${Math.random() * 5}s`;
      starContainer.appendChild(star);
    }
  }, []);

  return <div className="dot-container"></div>;
});

function App() {
  const [selectedApps, setSelectedApps] = useState([]);
  const [language, setLanguage] = useState('pl');
  const [instructionText, setInstructionText] = useState(translations[language].instruction);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const blockRightClick = (e) => {
      e.preventDefault();
    };

    document.addEventListener("contextmenu", blockRightClick);

    const blockKeyShortcuts = (e) => {
      if (
        (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'J' || e.key === 'C')) ||
        (e.ctrlKey && e.key === 'U') ||
        e.key === 'F12' ||
        (e.ctrlKey && e.key === 'C')
      ) {
        e.preventDefault();
      }
    };

    document.addEventListener("keydown", blockKeyShortcuts);

    const blockCopy = (e) => {
      e.preventDefault();
    };

    document.addEventListener("copy", blockCopy);
    document.addEventListener("selectstart", blockCopy);

    const checkIfMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android|iPad|iPhone|iPod/i.test(userAgent)) {
        setIsMobile(true);
      }
    };

    checkIfMobile();

    return () => {
      document.removeEventListener("contextmenu", blockRightClick);
      document.removeEventListener("keydown", blockKeyShortcuts);
      document.removeEventListener("copy", blockCopy);
      document.removeEventListener("selectstart", blockCopy);
    };
  }, []);

  useEffect(() => {
    const checkOverlap = () => {
      const navbarTutorial = document.querySelector('.navbar-tutorial');
      const navbarRight = document.querySelector('.navbar-right');

      if (navbarTutorial && navbarRight) {
        const navbarRightRect = navbarRight.getBoundingClientRect();
        const tutorialRect = navbarTutorial.getBoundingClientRect();

        if (tutorialRect.right > navbarRightRect.left) {
          const trimmedText = translations[language].instruction.slice(0, 30) + "...";
          setInstructionText((prevText) => {
            if (prevText !== trimmedText) {
              navbarTutorial.setAttribute('title', translations[language].instruction);
              return trimmedText;
            }
            return prevText;
          });
        } else {
          const fullText = translations[language].instruction;
          setInstructionText((prevText) => {
            if (prevText !== fullText) {
              navbarTutorial.removeAttribute('title');
              return fullText;
            }
            return prevText;
          });
        }
      }
    };

    checkOverlap();
    window.addEventListener('resize', checkOverlap);

    return () => {
      window.removeEventListener('resize', checkOverlap);
    };
  }, [language]);

  useEffect(() => {
    const handleMiddleClick = (e) => {
      if (e.button === 1) {
        e.preventDefault();
        const target = e.target;

        if (target.classList.contains('category') || target.closest('.category')) {
          const categoryBox = target.closest('.category');
          if (categoryBox) {
            const categoryIndex = Array.from(document.querySelectorAll('.category')).indexOf(categoryBox);
            if (categoryIndex >= 0) {
              const appsInCategory = apps[categoryIndex].apps;
              setSelectedApps((prevSelected) => {
                const areAllSelected = appsInCategory.every(app => prevSelected.includes(app));
                if (areAllSelected) {
                  return prevSelected.filter(app => !appsInCategory.includes(app));
                } else {
                  const newSelected = new Set(prevSelected);
                  appsInCategory.forEach(app => newSelected.add(app));
                  return Array.from(newSelected);
                }
              });
            }
          }
        }
      }
    };

    document.addEventListener('mouseup', handleMiddleClick);

    return () => {
      document.removeEventListener('mouseup', handleMiddleClick);
    };
  }, []);

  const handleSelectApp = (app) => {
    setSelectedApps((prevSelected) => {
      if (prevSelected.includes(app)) {
        return prevSelected.filter((selected) => selected !== app);
      } else {
        return [...prevSelected, app];
      }
    });
  };

  const switchLanguage = (lang) => {
    if (lang === language) return;

    setLanguage(lang);
    setInstructionText(translations[lang].instruction);
  };

  const isDisabled = selectedApps.length === 0;
  const t = translations[language];

  if (isMobile) {
    return (
      <h1 className="mobile-warning">
        Urządzenia mobilne nie są wspierane / Mobile devices are not supported.
      </h1>
    );
  }

  return (
    <Router>
      <div className="main-container">
        <FallingStars />

        <div className="navbar">
          <div className="navbar-left">
            <Link to="/" className="navbar-title">
              Appset
            </Link>
          </div>

          <div className="navbar-tutorial">
            <strong>{instructionText}</strong>
          </div>

          <div className="navbar-right">
            <Link to="/help">{t.help || 'Help'}</Link>
            <Link to="/feedback">{t.feedback || 'Feedback'}</Link>

            <div className="flags">
              <img
                src="/images/BR.png"
                alt="English"
                className="flag-icon"
                onClick={() => switchLanguage('en')}
              />
              <img
                src="/images/PL.png"
                alt="Polish"
                className="flag-icon"
                onClick={() => switchLanguage('pl')}
              />
            </div>
          </div>
        </div>

        <Routes>
          <Route
            path="/"
            element={
              <MainContent
                t={t}
                apps={apps}
                selectedApps={selectedApps}
                handleSelectApp={handleSelectApp}
                isDisabled={isDisabled}
                language={language}
              />
            }
          />
          <Route path="/help" element={<Help language={language} />} />
          <Route path="/feedback" element={<FeedbackPage language={language} />} />
        </Routes>
      </div>
    </Router>
  );
}

function MainContent({ t, apps, selectedApps, handleSelectApp, isDisabled }) {
  const { isDownloading, setIsDownloading } = useDownload();
  const [showLargeFileMessage, setShowLargeFileMessage] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    let timer;
    if (isDownloading) {
      timer = setTimeout(() => {
        setShowLargeFileMessage(true);
        setTimeout(() => setFadeIn(true), 100);
      }, 15000);
    } else {
      setShowLargeFileMessage(false);
      setFadeIn(false);
    }

    return () => clearTimeout(timer);
  }, [isDownloading]);

 const handleDownload = () => {
  if (isDownloading) {
    console.log('Already downloading...');
    return;
  }

  if (isDisabled) {
    console.log('No apps selected for download.');
    return;
  }

  setIsDownloading(true);
  console.log('Sending download request for selected apps:', selectedApps);

  axios.post(
    `https://appset.lol/api/create-installer-zip`,
    { selectedApps: selectedApps },
    { responseType: 'blob' }
  )
    .then((response) => {
      console.log('Download response received, preparing file for download.');

      const blob = new Blob([response.data], { type: 'application/zip' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'appsets.zip';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setIsDownloading(false);
    })
    .catch((error) => {
      console.error('Error downloading ZIP:', error);
      setIsDownloading(false);
    });
};


  return (
    <div className="app-container">
      <h1 className="header">{t.title}</h1>
      <div className="categories-container">
        {apps.map((categoryData, index) => (
          <div key={index} className="category">
            <h2 className="category-title">
              {t.categories[categoryData.category] || categoryData.category}
            </h2>
            {categoryData.apps.map((app, appIndex) => (
              <label key={appIndex} className="app-label">
                <input
                  type="checkbox"
                  value={app}
                  className="app-checkbox"
                  onChange={() => handleSelectApp(app)}
                  checked={selectedApps.includes(app)}
                />
                <span>{app}</span>
              </label>
            ))}
          </div>
        ))}
      </div>

      <button
        className={`download-button ${isDownloading ? 'disabled' : (isDisabled ? 'disabled' : '')}`}
        disabled={isDownloading || isDisabled}
        onClick={handleDownload}
      >
        {isDownloading ? t.downloading : (isDisabled ? t.noSelection : t.downloadButton)}
      </button>

      {showLargeFileMessage && (
        <div className={`large-file-message ${fadeIn ? 'fade-in' : ''}`}>
          {t.largeFileMessage}
        </div>
      )}
    </div>
  );
}

export default App;